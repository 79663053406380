<template>
    <div class="ranking">
        <!-- 威有U品 -->
        <div class="rkBox">
            <!-- 导航栏 -->
            <div class="rbTop">
                <div class="rbTitle">威有U品</div>
                <div class="rbTabs">
                    <ul>
                        <li class="rtLi" :class="{ rbActive: tabsB === 1 }" @click="showRb(1)">小程序</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 2 }" @click="showRb(2)">网站建设</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 3 }" @click="showRb(3)">基础软件</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 4 }" @click="showRb(4)">专家服务</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 5 }" @click="showRb(5)">企业应用</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 6 }" @click="showRb(6)">安全</li>
                        <li class="rtLi" :class="{ rbActive: tabsB === 7 }" @click="showRb(7)">API</li>
                    </ul>
                </div>
            </div>
            <!-- 内容区域 -->
            <div class="rbContent" v-show="showRbs === 1">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">小程序</div>
                        <div class="rlb2">
                            覆盖8个主要行业赛道的小程序建站模板，具备线上业务运营、 营销推广和客户管理全生命周期能力矩阵
                        </div>
                        <div class="rlb3">
                            <div class="rlb3Btn">电商/零售<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">餐饮/外卖<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">生活方式<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">威有微商城小程序</div>
                                <div class="eb2">
                                    <p class="eb2P">3分钟创建线上商城，全渠道卖货</p>
                                    <p class="eb2P">分销、社区团购等百余种营销玩法</p>
                                    <p class="eb2P">精细化会员管理，提升复购</p>
                                </div>
                                <div class="eb3">0元试用</div>
                                <div class="eb4">威有6800/年</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 2">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">网站建设</div>
                        <div class="rlb2">海量企业形象展示模板，即刻拥有您的专属品牌形象</div>
                        <div class="rlb3">
                            <div class="rlb3Btn">企业官网<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">电商网站<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">网站模板<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">网站定制<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">
                                    云响应式开发系统（送推广）<img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                        alt=""
                                        class="eb1Img"
                                    />
                                </div>
                                <div class="eb2">
                                    <p class="eb2P">自适应网站适合不同屏幕大小</p>
                                    <p class="eb2P">节省300元官方认证费</p>
                                    <p class="eb2P">赠送10个城市站群推广，提高收录</p>
                                </div>
                                <div class="eb3">高性价比</div>
                                <div class="eb4">威有0/7天试用 威有870年</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 3">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">基础软件</div>
                        <div class="rlb2">为您的IT环境提供软件支持</div>
                        <div class="rlb3">
                            <div class="rlb3Btn">运行环境<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">操作系统<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">开发工具<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">安全<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">手机网站建设公众号定制开发</div>
                                <div class="eb2">
                                    <p class="eb2P">企业级解决方案，专业开发6余年</p>
                                    <p class="eb2P">公众号建设</p>
                                    <p class="eb2P">专业建站六余年</p>
                                </div>
                                <div class="eb3">高性价比</div>
                                <div class="eb4">威有40000/20日</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 4">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">专家服务</div>
                        <div class="rlb2">
                            为您提供应用、安全、数据等各种服务来提升用户应用系统性能 ，让用户的应用和服务在云上更好的运作
                        </div>
                        <div class="rlb3">
                            <div class="rlb3Btn">企业服务<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">镜像服务<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">上云迁移<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">部署实施<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">网站漏洞检测</div>
                                <div class="eb2">
                                    <p class="eb2P">支持系统漏洞扫描和web漏洞扫描</p>
                                    <p class="eb2P">涵括上千种检测策略</p>
                                    <p class="eb2P">深入多种网站架设平台及网站开发种类</p>
                                </div>
                                <div class="eb3">热销</div>
                                <div class="eb4">威有9866/次</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 5">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">企业应用</div>
                        <div class="rlb2">提供一站式提升企业经营效率的应用集合</div>
                        <div class="rlb3">
                            <div class="rlb3Btn">办公管理<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">销售管理<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">财务管理<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">人事管理<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">
                                    泛微eteams<img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                        alt=""
                                        class="eb1Img"
                                    />
                                </div>
                                <div class="eb2">
                                    <p class="eb2P">全程电子化管理</p>
                                    <p class="eb2P">处理进度透明化</p>
                                    <p class="eb2P">自定义设计样式</p>
                                </div>
                                <div class="eb3">高性价比</div>
                                <div class="eb4">威有0/7天试用 威有24950年</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 6">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">安全</div>
                        <div class="rlb2">多重安全服务，为您的业务保驾护航</div>
                        <div class="rlb3">
                            <div class="rlb3Btn">账户安全设计<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">应用安全<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">网络安全<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">主机安全<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">安恒云堡垒机许可（企业版）</div>
                                <div class="eb2">
                                    <p class="eb2P">统一的身份认证</p>
                                    <p class="eb2P">细致的授权管控</p>
                                    <p class="eb2P">多样的资产运维</p>
                                </div>
                                <div class="eb3">高性价比</div>
                                <div class="eb4">威有0/7天</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rbContent" v-show="showRbs === 7">
                <div class="rcLeft">
                    <img src="http://iv.okvu.cn/vugw/img/test4.png" alt="" class="rlBg" />
                    <div class="rlBox">
                        <div class="rlb1">API</div>
                        <div class="rlb2">覆盖多行业、多场景的API接口服务，为开发者提供便利数据服务</div>
                        <div class="rlb3">
                            <div class="rlb3Btn">电子商务<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">金融理财<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">生活服务<i class="iconfont icon-youbianjiantou"></i></div>
                            <div class="rlb3Btn">企业管理<i class="iconfont icon-youbianjiantou"></i></div>
                        </div>
                        <div class="rlb4">查看更多</div>
                    </div>
                </div>
                <div class="rcRight">
                    <ul>
                        <li class="erLi" v-for="item in 4" :key="item">
                            <div class="erlBox">
                                <div class="eb1">运营商三要素认证</div>
                                <div class="eb2">
                                    <p class="eb2P">数据实时更新</p>
                                    <p class="eb2P">直连权威数据源</p>
                                    <p class="eb2P">多维度精准数据</p>
                                </div>
                                <div class="eb3">高性价比</div>
                                <div class="eb4">威有0/年</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Ranking",
    data() {
        return {
            tabsB: 1,
            showRbs: 1,
        };
    },
    methods: {
        showRb(i) {
            this.tabsB = i;
            this.showRbs = i;
        },
    },
};
</script>

<style lang="less" scoped>
.ranking {
    width: 100%;
    height: 650px;
    margin-top: 40px;
    .rkBox {
        width: 1280px;
        height: 477px;
        margin: 0 auto;
        background-color: white;
        border-radius: 40px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        // 导航栏
        .rbTop {
            width: 100%;
            height: 120px;
            overflow: hidden;
            .rbTitle {
                height: 35px;
                line-height: 35px;
                font-size: 25px;
                margin-left: 20px;
                margin-top: 20px;
            }
            .rbTabs {
                width: 100%;
                height: 50px;
                margin-top: 15px;
                border-bottom: 1px solid #eee;
                ul {
                    width: 700px;
                    height: 100%;
                    display: flex;
                    margin-left: 20px;
                    .rtLi {
                        min-width: 45px;
                        height: 47px;
                        line-height: 47px;
                        font-size: 17px;
                        cursor: pointer;
                        text-align: center;
                        position: relative;
                    }
                    // 底部从中间慢慢显示
                    .rtLi::before,
                    .rtLi::after {
                        content: "";
                        width: 0;
                        height: 3px;
                        background-color: #006eff;
                        position: absolute;
                        top: 99%;
                        left: 50%;
                        transition: all 0.5s;
                    }
                    .rtLi:hover::before {
                        width: 100%;
                        left: 0;
                    }
                    .rtLi:after::before {
                        width: 100%;
                        left: 0;
                    }
                    .rtLi:nth-child(n + 2) {
                        margin-left: 32px;
                    }
                    .rbActive {
                        color: #006eff;
                        font-weight: 900;
                        border-bottom: 3px solid #006eff;
                    }
                }
            }
        }
        // 内容区域
        .rbContent {
            width: 100%;
            height: 477px;
            margin-top: 43px;
            border-radius: 40px;
            background-color: white;
            .rcLeft {
                width: 35%;
                height: 100%;
                float: left;
                position: relative;
                .rlBg {
                    width: 100%;
                    height: 100%;
                    border-bottom-left-radius: 40px;
                }
                .rlBox {
                    width: 313px;
                    height: 320px;
                    position: absolute;
                    top: 50px;
                    left: 70px;
                    color: white;
                    .rlb1 {
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        font-size: 18px;
                        padding-left: 10px;
                    }
                    .rlb2 {
                        width: 100%;
                        height: 72px;
                        font-size: 14px;
                        line-height: 24px;
                        margin-top: 10px;
                        padding-left: 10px;
                    }
                    .rlb3 {
                        width: 100%;
                        height: 50px;
                        margin-top: 20px;
                        .rlb3Btn {
                            min-width: 86px;
                            height: 25px;
                            margin-top: 10px;
                            float: left;
                            line-height: 25px;
                            text-align: center;
                            border-radius: 5px;
                            margin-left: 10px;
                            font-size: 14px;
                            color: white;
                            cursor: pointer;
                            background-color: rgba(226, 220, 220, 0.2);
                            transition: 0.5s;
                        }
                        .rlb3Btn:hover {
                            background-color: white;
                            color: #006eff;
                        }
                    }
                    .rlb4 {
                        width: 100px;
                        height: 40px;
                        margin-top: 40px;
                        margin-left: 10px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 5px;
                        color: white;
                        background-color: #ffa219;
                    }
                }
            }
            .rcRight {
                width: 65%;
                height: 100%;
                float: left;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .erLi {
                        width: 50%;
                        height: 50%;
                        box-sizing: border-box;
                        transition: 0.5s;
                        .erlBox {
                            width: 80%;
                            height: 80%;
                            margin: 0 auto;
                            margin-top: 25px;
                            .eb1 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 16px;
                                padding-left: 10px;
                            }
                            .eb2 {
                                width: 100%;
                                height: 70px;
                                .eb2P {
                                    font-size: 12px;
                                    color: gray;
                                    margin-top: 10px;
                                    padding-left: 10px;
                                }
                            }
                            .eb3 {
                                width: 60px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                                border-radius: 5px;
                                border: 1px solid #2cb86a;
                                color: #2cb86a;
                                font-size: 12px;
                                margin-top: 10px;
                                margin-left: 10px;
                            }
                            .eb4 {
                                width: 100%;
                                height: 35px;
                                line-height: 35px;
                                color: #ff791f;
                                font-size: 20px;
                                margin-top: 10px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .erLi:hover {
                        box-shadow: 1px 1px 10px 1px gainsboro;
                    }
                    .erLi:nth-child(4) {
                        border-bottom-right-radius: 40px;
                    }
                    // .erLi:nth-child(1) {
                    //   border-right: 1px solid gray;
                    //   border-bottom: 1px solid gray;
                    // }
                    // .erLi:nth-child(2) {
                    //   border-bottom: 1px solid gray;
                    // }
                    // .erLi:nth-child(3) {
                    //   border-right: 1px solid gray;
                    // }
                }
            }
        }
    }
}
</style>
