<template>
    <!-- 威有云 -->
    <div class="vyCloud" style="background-color: #f83c3d">
        <!-- 头部 -->
        <VcTop></VcTop>
        <div style="margin: 10px 0; background-color: blue">ccc</div>
        <!-- 威有臻选 -->
        <YouLikes></YouLikes>
        <div style="margin: 10px 0; background-color: blue">ccc</div>
        <!-- 威有U选 -->
        <Experience></Experience>
        <div style="margin: 10px 0; background-color: blue">ccc</div>
        <!-- 威有U品 -->
        <Ranking></Ranking>
    </div>
</template>

<script>
import VcTop from "../components/content/VyClouds/VcTop/VcTop.vue";
import YouLikes from "../components/content/VyClouds/YouLikes/YouLikes.vue";
import Experience from "../components/content/VyClouds/Experience/Experience.vue";
import Ranking from "../components/content/VyClouds/Ranking/Ranking.vue";

export default {
    name: "vyCloud",
    components: {
        VcTop,
        YouLikes,
        Experience,
        Ranking,
    },
};
</script>

<style lang="less" scoped>
.vyCloud {
    margin: 0 auto;
}
</style>
