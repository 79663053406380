<template>
    <div class="experience">
        <!-- 威有U选 -->
        <div class="eiBox">
            <!-- 导航栏 -->
            <div class="eiTop">
                <div class="eiTitle">威有U选</div>
                <div class="eiTabs">
                    <ul>
                        <li class="etLi" :class="{ elActive: tabsB === 1 }" @click="showEl(1)">API服务</li>
                        <li class="etLi" :class="{ elActive: tabsB === 2 }" @click="showEl(2)">企业应用</li>
                        <li class="etLi" :class="{ elActive: tabsB === 3 }" @click="showEl(3)">小程序</li>
                        <li class="etLi" :class="{ elActive: tabsB === 4 }" @click="showEl(4)">网站建设</li>
                    </ul>
                </div>
            </div>
            <!-- 商品列表 -->
            <div class="eiList" v-show="showEls === 1">
                <ul>
                    <li class="elLi" v-for="item in 8" :key="item">
                        <div class="ellBox">
                            <div class="eb1">身份证实名认证</div>
                            <div class="eb2">
                                <p class="eb2P">免费试用实时效验</p>
                                <p class="eb2P">直连官方毫秒响应</p>
                                <p class="eb2P">效验准确100%可靠运营</p>
                            </div>
                            <div class="eb3">0元试用</div>
                            <div class="eb4">威有0/年</div>
                            <div class="eb5">
                                <div class="eb5Btn">联系威有</div>
                                <div class="eb5Btn">立即想要</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="eiList" v-show="showEls === 2">
                <ul>
                    <li class="elLi" v-for="item in 8" :key="item">
                        <div class="ellBox">
                            <div class="eb1">
                                企微管家：会话存档
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                    alt=""
                                    class="eb1Img"
                                />
                            </div>
                            <div class="eb2">
                                <p class="eb2P">合规化存档企业微信聊天记录内容</p>
                                <p class="eb2P">企业微信内容语义分析</p>
                                <p class="eb2P">员工服务会话内容审计</p>
                            </div>
                            <div class="eb3">热销</div>
                            <div class="eb4">威有0/7日</div>
                            <div class="eb5">
                                <div class="eb5Btn">联系威有</div>
                                <div class="eb5Btn">立即想要</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="eiList" v-show="showEls === 3">
                <ul>
                    <li class="elLi" v-for="item in 8" :key="item">
                        <div class="ellBox">
                            <div class="eb1">
                                威有-连锁门店管理系统
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                    alt=""
                                    class="eb1Img"
                                />
                            </div>
                            <div class="eb2">
                                <p class="eb2P">100多家连锁零售品牌推荐产品</p>
                                <p class="eb2P">可独立部署，支持品牌定制开发</p>
                                <p class="eb2P">软硬件配套</p>
                            </div>
                            <div class="eb3">0元试用</div>
                            <div class="eb4">威有0/7日</div>
                            <div class="eb5">
                                <div class="eb5Btn">联系威有</div>
                                <div class="eb5Btn">立即想要</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="eiList" v-show="showEls === 4">
                <ul>
                    <li class="elLi" v-for="item in 8" :key="item">
                        <div class="ellBox">
                            <div class="eb1">H5响应式网站</div>
                            <div class="eb2">
                                <p class="eb2P">响应式精美模板，可视化编辑</p>
                                <p class="eb2P">自适应电脑/平板/手机/微信</p>
                                <p class="eb2P">赠送SSL加密证书验</p>
                            </div>
                            <div class="eb3">0元试用</div>
                            <div class="eb4">威有0/7天试用 威有1/30日</div>
                            <div class="eb5">
                                <div class="eb5Btn">联系威有</div>
                                <div class="eb5Btn">立即想要</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Experience',
    data() {
        return {
            tabsB: 1,
            showEls: 1,
        };
    },
    methods: {
        showEl(i) {
            this.tabsB = i;
            this.showEls = i;
        },
    },
};
</script>

<style lang="less" scoped>
.experience {
    width: 100%;
    height: 830px;
    margin-top: 40px;
    .eiBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        background-color: white;
        border-radius: 40px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        // 导航栏
        .eiTop {
            width: 100%;
            height: 120px;
            overflow: hidden;
            .eiTitle {
                height: 35px;
                line-height: 35px;
                font-size: 25px;
                margin-left: 20px;
                margin-top: 20px;
            }
            .eiTabs {
                width: 100%;
                height: 50px;
                margin-top: 15px;
                border-bottom: 1px solid #eee;
                ul {
                    width: 400px;
                    height: 100%;
                    display: flex;
                    margin-left: 20px;
                    .etLi {
                        min-width: 48px;
                        height: 47px;
                        line-height: 47px;
                        font-size: 17px;
                        cursor: pointer;
                        text-align: center;
                        position: relative;
                    }
                    // 底部从中间慢慢显示
                    .etLi::before,
                    .etLi::after {
                        content: '';
                        width: 0;
                        height: 3px;
                        background-color: #006eff;
                        position: absolute;
                        top: 99%;
                        left: 50%;
                        transition: all 0.5s;
                    }
                    .etLi:hover::before {
                        width: 100%;
                        left: 0;
                    }
                    .etLi:after::before {
                        width: 100%;
                        left: 0;
                    }
                    .etLi:nth-child(n + 2) {
                        margin-left: 32px;
                    }
                    .elActive {
                        color: #006eff;
                        font-weight: 900;
                        border-bottom: 3px solid #006eff;
                    }
                }
            }
        }
        // 商品列表
        .eiList {
            width: 100%;
            height: 600px;
            margin-top: 10px;
            background-color: white;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .elLi {
                    width: 23%;
                    height: 298px;
                    border-radius: 10px;
                    margin-top: 20px;
                    background-color: white;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    transition: 0.5s;
                    .ellBox {
                        width: 80%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 30px;
                        .eb1 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            font-size: 14px;
                            font-weight: 900;
                            padding-left: 10px;
                        }
                        .eb2 {
                            width: 100%;
                            height: 70px;
                            .eb2P {
                                font-size: 12px;
                                color: gray;
                                margin-top: 10px;
                                padding-left: 10px;
                            }
                        }
                        .eb3 {
                            width: 60px;
                            height: 20px;
                            line-height: 20px;
                            text-align: center;
                            border-radius: 5px;
                            border: 1px solid #2cb86a;
                            color: #2cb86a;
                            font-size: 12px;
                            margin-top: 10px;
                            margin-left: 10px;
                        }
                        .eb4 {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            color: #ff791f;
                            font-size: 20px;
                            margin-top: 10px;
                            margin-left: 10px;
                        }
                        .eb5 {
                            width: 100%;
                            height: 36px;
                            line-height: 36px;
                            margin-top: 13px;
                            .eb5Btn {
                                width: 100px;
                                height: 36px;
                                text-align: center;
                                color: #ff791f;
                                margin-left: 10px;
                                float: left;
                                border: 1px solid #ff791f;
                            }
                            .eb5Btn:nth-child(2) {
                                background-color: #ff791f;
                                color: white;
                            }
                            .eb5Btn:hover {
                                background-color: #eeb38b;
                            }
                        }
                    }
                }
                .elLi:hover {
                    box-shadow: 1px 1px 10px 1px rgb(182, 181, 181);
                }
            }
        }
    }
}
</style>
