<template>
    <div class="youLikes">
        <div class="ylBox">
            <!-- 标题 -->
            <div class="ylTitle">
                <p class="ytP">威有臻选</p>
                <div class="ytLabel">
                    <img src="https://gw.alicdn.com/imgextra/i2/O1CN016b1mMM1FxJlsXfWhU_!!6000000000553-2-tps-96-30.png" alt="" class="ytlImg" />
                </div>
            </div>
            <!-- 商品列表 -->
            <div class="infinite-list-wrapper">
                <ul class="list" style="overflow: auto" v-infinite-scroll="load" infinite-scroll-disabled="false">
                    <li v-for="i in count" class="list-item" :key="i">
                        <div class="liLeft">
                            <img
                                src="https://gw.alicdn.com/bao/uploaded/i1/3463192241/O1CN01qPcFSf1SQQAWAm3oX_!!0-item_pic.jpg_300x300q90.jpg_.webp"
                                alt=""
                                class="lilImg"
                            />
                        </div>
                        <div class="liRight">
                            <div class="lirText">
                                <div class="lirTop">
                                    <img src="https://gw.alicdn.com/tfs/TB1ZgdFKAL0gK0jSZFtXXXQCXXa-171-42.png" alt="" class="lirImg" />
                                    <p class="lirP">夏季潮流韩版衬衫短袖POLO衫2022新款样式</p>
                                </div>
                                <div class="lirIcon">送运费险</div>
                                <div class="lirIcon">每300减50</div>
                            </div>
                            <div class="lirPirce">威有 15.5</div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 底部信息 -->
            <div class="ylBottom">
                <ul>
                    <li class="ylbLi">
                        <div class="ylbTop">
                            <i class="iconfont icon-yanzhengyanzhengma"></i>
                            <p class="ytP">消费者保障</p>
                        </div>
                        <div class="ylbPs">
                            <ul class="ypUl">
                                <li class="ypLi">保障范围</li>
                                <li class="ypLi">退货退款流程</li>
                                <li class="ypLi">服务中心</li>
                                <li class="ypLi">更多特色服务</li>
                            </ul>
                        </div>
                    </li>
                    <li class="ylbLi">
                        <div class="ylbTop">
                            <i class="iconfont icon-kefu"></i>
                            <p class="ytP">新手上路</p>
                        </div>
                        <div class="ylbPs">
                            <ul class="ypUl">
                                <li class="ypLi">新手专区</li>
                                <li class="ypLi">24小时在线帮助</li>
                                <li class="ypLi">免费开店</li>
                            </ul>
                        </div>
                    </li>
                    <li class="ylbLi">
                        <div class="ylbTop">
                            <i class="iconfont icon-jinrong"></i>
                            <p class="ytP">付款方式</p>
                        </div>
                        <div class="ylbPs">
                            <ul class="ypUl">
                                <li class="ypLi">快捷支付</li>
                                <li class="ypLi">信用卡</li>
                                <li class="ypLi">威有</li>
                                <li class="ypLi">威有</li>
                            </ul>
                        </div>
                    </li>
                    <li class="ylbLi">
                        <div class="ylbTop">
                            <i class="iconfont icon-lianxiren"></i>
                            <p class="ytP">威有特色</p>
                        </div>
                        <div class="ylbPs">
                            <ul class="ypUl">
                                <li class="ypLi">手机威有</li>
                                <li class="ypLi">威有</li>
                                <li class="ypLi">威有</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YouLikes',
    data() {
        return {
            count: 0,
        };
    },
    methods: {
        load() {
            if (this.count <= 50) {
                this.count += 3;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.youLikes {
    width: 100%;
    min-height: 800px;
    .ylBox {
        width: 1280px;
        min-height: 700px;
        margin: 0 auto;
        background-color: white;
        border-radius: 40px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        // 标题
        .ylTitle {
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding-top: 10px;
            box-sizing: border-box;
            .ytP {
                width: 150px;
                height: 50px;
                font-size: 25px;
                text-align: center;
                float: left;
            }
            .ytLabel {
                width: 64px;
                height: 20px;
                float: left;
                margin-top: 5px;
                .ytlImg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        // 商品列表
        .infinite-list-wrapper {
            width: 100%;
            background-color: white;
            .list {
                width: 100%;
                min-height: 700px;
                display: flex;
                flex-wrap: wrap;
                .list-item {
                    width: 370px;
                    height: 174px;
                    margin-top: 20px;
                    margin-left: 40px;
                    background-color: white;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    transition: 0.5s;
                    .liLeft {
                        width: 150px;
                        height: 150px;
                        margin-left: 10px;
                        margin-top: 12px;
                        float: left;
                        overflow: hidden;
                        border-radius: 10px;
                        .lilImg {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            transition: 0.5s;
                        }
                    }
                    .liRight {
                        width: 186px;
                        height: 140px;
                        float: left;
                        .lirText {
                            width: 100%;
                            height: 116px;
                            margin-top: 20px;
                            margin-left: 10px;
                            .lirTop {
                                width: 100%;
                                height: 46px;
                                position: relative;
                                .lirImg {
                                    width: 65px;
                                    height: 16px;
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                }
                                .lirP {
                                    line-height: 21px;
                                    text-indent: 70px;
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                            }
                            .lirIcon {
                                width: 58px;
                                height: 20px;
                                float: left;
                                line-height: 20px;
                                text-align: center;
                                border: 1px solid #ff5000;
                                border-radius: 5px;
                                font-size: 12px;
                                color: #ff5000;
                            }
                            .lirIcon:nth-child(3) {
                                width: 65px;
                                height: 20px;
                                margin-left: 10px;
                            }
                        }
                        .lirPirce {
                            width: 100%;
                            height: 24px;
                            font-size: 22px;
                            line-height: 22px;
                            margin-left: 10px;
                            color: #ff5000;
                        }
                    }
                }
                .list-item:hover {
                    box-shadow: 1px 1px 10px 1px gray;

                    .lilImg {
                        transform: scale(1.1);
                    }
                }
            }
        }
        // 底部信息
        .ylBottom {
            width: 100%;
            height: 150px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .ylbLi {
                    width: 23%;
                    height: 100%;
                    .ylbTop {
                        width: 90%;
                        height: 50px;
                        line-height: 50px;
                        margin: 0 auto;
                        margin-top: 10px;
                        font-weight: 900;
                        .iconfont {
                            font-size: 40px;
                            float: left;
                        }
                        .ytP {
                            float: left;
                            margin-left: 20px;
                        }
                    }
                    .ylbPs {
                        width: 90%;
                        height: 45%;
                        margin: 0 auto;
                        margin-top: 10px;
                        .ypUl {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            .ypLi {
                                min-width: 48px;
                                height: 21px;
                                line-height: 21px;
                                font-size: 12px;
                                cursor: pointer;
                            }
                            .ypLi:hover {
                                color: #ff5000;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
